import React from 'react';
import './style.css';

const SuspenseFallback = () => {
  return (
    <div
      className='d-flex justify-content-center align-items-center flex-column'
      style={{ height: '100vh', width: '100%' }}
    >
      <button class='btn-spinner'>
        <span class='spinner'></span>
        <span className='btn__text'>Loading..</span>
      </button>
    </div>
  );
};

export default SuspenseFallback;
