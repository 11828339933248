import React from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import Route from "./routes";

// universal CSS Import
import "bootstrap/dist/css/bootstrap.min.css";
import "react-pro-sidebar/dist/css/styles.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-tippy/dist/tippy.css";
import "react-multi-carousel/lib/styles.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-awesome-lightbox/build/style.css";

import "./index.css";
import { ThemeProvider } from "./utilities/ContextProvider/ThemeContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <RecoilRoot>
    {/* <ThemeProvider> */}
      <Route />
    {/* </ThemeProvider> */}
  </RecoilRoot>
);
