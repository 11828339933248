import React, { useEffect, useRef } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { IoRefresh } from 'react-icons/io5';
import { CaptchaBackground } from '../../assets/images';
import { ErrorToast, SuccessToastWithToastId } from '../customToast';
import './captchaStyle.css';

const Captcha = (props) => {
  const {
    generatedCaptcha,
    setGeneratedCaptcha,
    register,
    errors,
    formFieldStyle,
  } = props;

  let captcha;
  let alphabets = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz';

  const captchaInputRef = useRef();

  const generate = () => {
    let first = alphabets[Math.floor(Math.random() * alphabets.length)];
    let second = Math.floor(Math.random() * 10);
    let third = Math.floor(Math.random() * 10);
    let fourth = alphabets[Math.floor(Math.random() * alphabets.length)];
    let fifth = alphabets[Math.floor(Math.random() * alphabets.length)];
    let sixth = Math.floor(Math.random() * 10);

    captcha =
      first.toString() +
      second.toString() +
      third.toString() +
      fourth.toString() +
      fifth.toString() +
      sixth.toString();

    localStorage.setItem('captcha', captcha);
    setGeneratedCaptcha(captcha);
  };

  useEffect(() => {
    generate();
  }, []);

  return (
    <div>
      <Row>
        <Col xs={9} className='pe-0'>
          {/* captcha */}
          <div className='d-flex align-items-center justify-content-between'>
            <img
              src={CaptchaBackground}
              alt='captcha background'
              style={{
                objectFit: 'cover',
                height: '41px',
                width: '100%',
              }}
            />
          </div>
          <span className='captcha'>{generatedCaptcha}</span>
        </Col>

        <Col xs={3}>
          <div
            onClick={() => generate()}
            className=' d-flex align-items-center justify-content-center '
            style={{
              backgroundColor: 'rgb(11 146 75)',
              borderRadius: '6px',
              height: '40px',
            }}
          >
            <IoRefresh size={25} color='white' />
          </div>
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col style={{ position: 'relative', width: '400px' }}>
          <Form.Control
            type='text'
            onPaste={(e) => {
              e.preventDefault();
              ErrorToast('Can not paste on field!');
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              ErrorToast('Can not copy from field!');
              return false;
            }}
            style={{
              width: '100%',
              height: '40px',
              padding: '0 10px',
              borderRadius: 0,
              borderLeft: 'hidden',
              borderRight: 'hidden',
              borderTop: 'hidden',
            }}
            className=' mt-2 login-form-field'
            placeholder='Enter the captcha..'
            ref={captchaInputRef}
            {...register('captcha', {
              required: 'Captcha required!',
            })}
          />
          {errors.captcha && (
            <small className='text-danger'>{errors.captcha.message}</small>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Captcha;
