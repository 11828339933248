import React, { useState, useRef } from "react";
// import { encryptData } from '../../utilities/encryption/encrypt';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import SendOtpForm from "./SendOtpForm";
import { contactNumberValidator } from "../../utilities/validators/mobileNumber.validation";
import { OIIPCRALogo } from "../../assets/images";
import Captcha from "../../components/captcha";
import Helmet from "../../components/helmet";
import {
  ErrorToastWithToastId,
  SuccessToastWithToastId,
  ErrorToast,
  SuccessToast,
} from "../../components/customToast";
import {
  LoginHelper,
  ForgotPassHelper,
  VerifyPassHelper,
} from "../../services/login/loginHelper";

import { menuListAtom } from "../../recoil/atoms/menulist/menuList.atom";
import { tankMenuIdAtom } from "../../recoil/atoms/tank/tankMenuId.atom";
import { surveyMenuIdAtom } from "../../recoil/atoms/surveyData/surveyMenuId.atom";
import "./login.css";

const Login = () => {
  const history = useHistory();
  const otpRef = useRef();
  const forgotRef = useRef();
  const userNameRef = useRef();
  const userLoginRef = useRef();
  const userEmailRef = useRef();
  const signInTextRef = useRef();
  const otpSectionRef = useRef();
  const newPasswordRef = useRef();
  const forgotPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const newPasswordFormRef = useRef();

  const [, setMenuList] = useRecoilState(menuListAtom);
  const [, setTankMenuId] = useRecoilState(tankMenuIdAtom);
  const [, setSurveyMenuId] = useRecoilState(surveyMenuIdAtom);

  const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";

  const [loginOTP, setLoginOTP] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // form field design
  const formFieldStyle = {
    borderRadius: 0,
    borderLeft: "hidden",
    borderRight: "hidden",
    borderTop: "hidden",
  };

  // captcha
  const [generatedCaptcha, setGeneratedCaptcha] = useState("");

  const defaultValueForLoginForm = {
    mobileNumber: "",
    password: "",
  };

  // react hook form for login
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues: defaultValueForLoginForm });

  const defaultValueForForgotPassword = {
    mobileNumberForgot: "",
    emailIdForgot: "",
  };

  // react hook form for forgot password
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    getValues: getValues2,
    reset: reset2,
  } = useForm({ defaultValues: defaultValueForForgotPassword });

  // set default menu list
  const setDefaults = (menuList) => {
    var isDefaultMenu;
    if (menuList.length > 0) {
      menuList.forEach((menu) => {
        // if parent has child menu
        if (menu?.children.length > 0) {
          let childMenuList = menu?.children;
          childMenuList.forEach((children) => {
            if (children.module === "/tank") {
              setTankMenuId(children.value);
            }
            if (children.module === "/surveydata") {
              setSurveyMenuId(children.value);
            }
            if (children?.isDefault) {
              let moduleName = menu?.module;
              isDefaultMenu = moduleName;
            }
          });
        } else {
          if (menu.module === "/tank") {
            localStorage.setItem("tankMenuId", menu.value);
            setTankMenuId(menu.value);
          }
          if (menu.module === "/surveydata") {
            localStorage.setItem("surveyMenuId", menu.value);
            setSurveyMenuId(menu.value);
          }
          if (menu?.isDefault) {
            let moduleName = menu?.module;
            isDefaultMenu = moduleName;
          }
        }
      });
    }
    return isDefaultMenu;
  };

  // Login button handler
  const onSubmitLogin = (data) => {
    const { username } = data;
    data.loginFrom = "WEB";
    // start the loading for login
    const toastId = toast.loading("Logging In...");
    let userValue = getValues("captcha");

    if (userValue == generatedCaptcha) {
      delete data["captcha"];

      setTimeout(() => {
        LoginHelper(data)
          .then((response) => {
            if (response && response.status == 1) {
              let userInfo = response.data.user;
              // save user information into local storage
              // save menu list
              localStorage.setItem(
                "menu_list",
                JSON.stringify(response.data.menuList)
              );
              localStorage.setItem("user", JSON.stringify(userInfo));
              setMenuList(response.data.menuList);

              let menuList = response.data.menuList;
              // loop the json and set isDefault
              let defaultModule = setDefaults(menuList);

              reset();
              // redirect to the dashboard page
              SuccessToastWithToastId("Successfully Logged In!", toastId);
              history.push(defaultModule);
            } else {
              ErrorToastWithToastId(response.data.message, toastId);
              reset();
            }
          })
          .catch((error) => {
            ErrorToastWithToastId("Login Failed!", toastId);
            reset();
          });
      }, 1000);
    } else {
      ErrorToastWithToastId("Captcha Verification Failed!", toastId);
    }
  };

  const captchaProps = {
    generatedCaptcha: generatedCaptcha,
    setGeneratedCaptcha: setGeneratedCaptcha,
    register: register,
    errors: errors,
    getValues: getValues,
  };

  const onClickForgotButtonHandler = (event) => {
    event.preventDefault();
    userLoginRef.current.style.display = "none";
    forgotRef.current.style.display = "none";
    forgotPasswordRef.current.style.display = "block";
    signInTextRef.current.style.display = "none";
  };

  const onClickSendOTPButtonHandler = (data) => {
    let toastId = toast.loading("Sending...");

    localStorage.setItem("forgotMobileNo", data.mobile);

    ForgotPassHelper(JSON.stringify(data)).then((response) => {
      if (response && response.status == 1) {
        SuccessToastWithToastId("OTP Sent Successfully!!", toastId);
        localStorage.setItem("otp", response.data.otp);
        setLoginOTP(response.data.otp);
        forgotPasswordRef.current.style.display = "none";
        otpSectionRef.current.style.display = "block";
      } else if (response && response.status == 0) {
        ErrorToastWithToastId(response.message, toastId);
      }
    });
  };

  // On click Cancel button handler
  const onClickCancelButtonHandler = (event) => {
    event.preventDefault();
    reset2(defaultValueForForgotPassword);

    userLoginRef.current.style.display = "block";
    forgotRef.current.style.display = "block";
    signInTextRef.current.style.display = "block";

    if (otpSectionRef.current.style.display == "block")
      otpSectionRef.current.style.display = "none";

    if (newPasswordFormRef.current.style.display == "block")
      newPasswordFormRef.current.style.display = "none";

    if (forgotPasswordRef.current.style.display == "block")
      forgotPasswordRef.current.style.display = "none";
  };

  // On click password button handler
  const onClickSubmitButton = (event) => {
    event.preventDefault();
    if (
      newPasswordRef.current.value == "" ||
      confirmPasswordRef.current.value == ""
    ) {
      ErrorToast("All Fields are Required");
    } else {
      if (newPasswordRef.current.value === confirmPasswordRef.current.value) {
        // var form = new FormData();

        const jsonDataForVerify = {
          mobile: parseInt(localStorage.getItem("forgotMobileNo")),
          password: newPasswordRef.current.value,
        };

        // Call api
        VerifyPassHelper(jsonDataForVerify).then((response) => {
          if (response && response.status == 1) {
            SuccessToast("Password Changed Successfully!!");
            newPasswordFormRef.current.style.display = "none";
            forgotPasswordRef.current.style.display = "none";
            userLoginRef.current.style.display = "block";
            forgotRef.current.style.display = "block";
            signInTextRef.current.style.display = "block";
            history.push("/login");
          } else if (response && response.status == 0) {
            ErrorToast(response.message);
          }
        });
      } else {
        ErrorToast("Password does not Match!");
      }
    }
  };

  // On click verify button handler
  const onClickVerifyButtonHandler = (event) => {
    event.preventDefault();

    if (otpRef.current.value == "") {
      ErrorToast("All Fields are Required!");
    } else {
      if (parseInt(otpRef.current.value) !== parseInt(loginOTP)) {
        ErrorToast("Invalid OTP!");
        otpSectionRef.current.focus();
      } else {
        otpSectionRef.current.style.display = "none";
        newPasswordFormRef.current.style.display = "block";
        SuccessToast("OTP Verified Successfully!");
      }
    }
  };

  // cancel button handler for forgot password
  const onClickCancelButtonOfForgot = (event) => {
    event.preventDefault();
    userLoginRef.current.style.display = "block";
    forgotRef.current.style.display = "block";
    // signInTextRef.current.style.display = 'block';

    if (otpSectionRef.current.style.display == "block") {
      otpSectionRef.current.style.display = "none";
    }

    if (newPasswordFormRef.current.style.display == "block") {
      newPasswordFormRef.current.style.display = "none";
    }

    if (forgotPasswordRef.current.style.display == "block") {
      forgotPasswordRef.current.style.display = "none";
    }
  };

  // props for forgotpassword form
  const sendOtpProps = {
    forgotPasswordRef,
    register2,
    reset2,
    errors2,
    handleSubmit2,
    getValues2,
    onClickSendOTPButtonHandler,
    onClickCancelButtonOfForgot,
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <Helmet title={"Login | OIIPCRA"} />
      <div
        className="container-fluid login-page p-0 m-0"
        style={{ overflow: "hidden" }}
      >
        <div className="position-relative h-100">
          <div className="login-page-bg position-absolute  d-none d-sm-block">
            <div className="row h-100 align-items-center">
              <div className="col-auto d-none d-sm-block h-100 outer-bg">
                <div className="blank-width"></div>
              </div>
              <div className="col bg-success bg-gradient bg-opacity-50 h-100"></div>
            </div>
          </div>

          <div className="row justify-content-center align-items-center h-100 login-meta-box g-0">
            <div className="col-12">
              <div className="main-login shadow bg-white">
                <Row className="row h-100 align-items-center g-0">
                  <Col
                    xxl={4}
                    xl={4}
                    lg={4}
                    className="login-section ps-5 pe-5"
                  >
                    <div className="login-panel p-3 p-md-5">
                      <div className="d-sm-none">
                        <Row className="align-items-center">
                          <Col xs={4}></Col>
                          <Col xs={8} className="mt-3">
                            <h2
                              className="text-center opams-text"
                              style={{ fontWeight: "400" }}
                            >
                              <span className="text-danger">O</span>disha{" "}
                              <span className="text-danger">I</span>ntegrated{" "}
                              <span className="text-danger">I</span>rrigation{" "}
                              <span className="text-danger">P</span>roject for{" "}
                              <span className="text-danger">C</span>limate{" "}
                              <span className="text-danger">R</span>esilient{" "}
                              <span className="text-danger">A</span>griculture{" "}
                            </h2>
                          </Col>
                        </Row>

                        <hr />
                      </div>
                      {/* logo */}
                      <div className="d-flex pb-5 justify-content-center mb-4">
                        {/* <img src={OPAMSLogo} alt='opams logo' height={70} /> */}
                        <img src={OIIPCRALogo} alt="opams logo" height={70} />
                      </div>
                      {/* <h4 className='welcome-header-login pb-4'>
                        Welcome to <span className='text-danger'>OPAMS</span>
                      </h4> */}

                      {/* user login section start */}
                      <Form
                        id="userLogin"
                        name="userLogin"
                        ref={userLoginRef}
                        onSubmit={handleSubmit(onSubmitLogin)}
                      >
                        <input type="hidden" id="userData" name="userData" />
                        <div className="mb-4">
                          <Form.Group>
                            <Form.Control
                              type="telNo"
                              placeholder="Mobile No."
                              name="mobileNumber"
                              maxLength={10}
                              onKeyPress={(e) => {
                                // const charCode = e.key;
                                // const pattern = /^([0-9]{10})$/;
                                if (
                                  !`${e.target.value}${e.key}`.match(
                                    /^[0-9]{0,10}$/
                                  )
                                ) {
                                  // block the input if result does not match
                                  e.preventDefault();
                                  e.stopPropagation();
                                  return false;
                                }
                              }}
                              // onKeyPress={(e) => {
                              //   if (e.key === "e" || e.key === "-") {
                              //     e.preventDefault();
                              //   }
                              // }}
                              className="login-form-field"
                              style={formFieldStyle}
                              {...register("mobileNumber", {
                                required: "Mobile Number Required!",
                                validate: (value) =>
                                  contactNumberValidator(value) ||
                                  "Number Should be 10-Digit!",
                                valueAsNumber: true,
                              })}
                            />
                            {errors.mobileNumber && (
                              <small className="text-danger">
                                {errors.mobileNumber.message}
                              </small>
                            )}
                          </Form.Group>
                        </div>
                        <div className="mb-4">
                          <Form.Group
                            className="mb-3 pass-wrapper-login"
                            controlId="formPlaintextPassword"
                          >
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              name="password"
                              className="login-form-field"
                              style={formFieldStyle}
                              {...register("password", {
                                required: "Password Required!",
                              })}
                            />
                            {errors.password && (
                              <small className="text-danger">
                                {errors.password.message}
                              </small>
                            )}

                            {showPassword ? (
                              <BsFillEyeFill
                                className="eye-react-icons-login"
                                onClick={togglePasswordVisibility}
                                size={20}
                                fill={"#808080"}
                              />
                            ) : (
                              <BsFillEyeSlashFill
                                className="eye-react-icons-login"
                                onClick={togglePasswordVisibility}
                                size={20}
                                fill={"#808080"}
                              />
                            )}
                          </Form.Group>
                          <div>
                            <Captcha {...captchaProps} />
                          </div>
                        </div>
                        <div className="text-end mb-4">
                          <a
                            href="/#"
                            className="forgot-pass-link"
                            onClick={(event) =>
                              onClickForgotButtonHandler(event)
                            }
                          >
                            <div className="forgot-password">
                              Forgot Password?
                            </div>
                          </a>
                        </div>

                        <div className="d-flex text-center align-items-center justify-content-center w-100">
                          <button
                            className="px-4 btn login-button w-50"
                            onClick={() => handleSubmit(onSubmitLogin)}
                          >
                            Sign In
                          </button>
                        </div>
                      </Form>
                      {/* user login section end */}

                      {/* Forgot password section start */}
                      <SendOtpForm {...sendOtpProps} />

                      {/* Forgot password section End */}

                      {/* OTP section start */}
                      <form
                        id="userLogin"
                        name="userLogin"
                        method="post"
                        ref={otpSectionRef}
                        style={{ display: "none" }}
                      >
                        <input type="hidden" id="userData" name="userData" />
                        <div className="mb-4">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="OTP"
                            name="otp"
                            id="otp"
                            ref={otpRef}
                            required
                          />
                        </div>

                        <div className="d-flex align-content-center">
                          <Button
                            className="px-4 btn login-button"
                            onClick={(event) =>
                              onClickVerifyButtonHandler(event)
                            }
                          >
                            Verify
                          </Button>

                          <Button
                            className="px-4 btn cancel-button ms-3"
                            onClick={(event) =>
                              onClickCancelButtonHandler(event)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                      {/* OTP section End */}

                      {/* New Password Section start */}
                      <form
                        id="userLogin"
                        name="userLogin"
                        method="post"
                        style={{ display: "none" }}
                        ref={newPasswordFormRef}
                      >
                        <input type="hidden" id="userData" name="userData" />
                        <div className="mb-4">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="New Password"
                            name="userid"
                            id="userid"
                            required
                            ref={newPasswordRef}
                            onPaste={(e) => e.preventDefault()}
                          />
                        </div>
                        <div className="mb-4">
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            placeholder="Confirm Password"
                            ref={confirmPasswordRef}
                            onPaste={(e) => e.preventDefault()}
                          />
                        </div>
                        <div className="d-flex align-content-center">
                          <Button
                            className="px-4 btn login-button"
                            onClick={(event) => onClickSubmitButton(event)}
                          >
                            Submit
                          </Button>
                          <Button
                            className="px-4 btn cancel-button ms-3"
                            onClick={(event) =>
                              onClickCancelButtonHandler(event)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                      {/* New Password Section end */}

                      {/* Forgot password section start*/}
                      <div
                        className="pb-3"
                        style={{ display: "block" }}
                        ref={forgotRef}
                      ></div>
                      {/* Forgot password section end*/}
                    </div>
                  </Col>
                  <Col
                    xxl={8}
                    xl={8}
                    lg={8}
                    className="d-none d-sm-block h-100"
                  >
                    <div className="h-100 login-bg bg-light">
                      <div className="p-5 d-flex justify-content-center align-items-center h-100">
                        <div
                          className="text-center display-4 text-white"
                          style={{ fontWeight: "400" }}
                        >
                          <span
                            className="text-warning"
                            style={{ color: "#e2d023" }}
                          >
                            O
                          </span>
                          disha{" "}
                          <span
                            className="text-warning"
                            style={{ color: "#e2d023" }}
                          >
                            I
                          </span>
                          ntegrated{" "}
                          <span
                            className="text-warning"
                            style={{ color: "#e2d023" }}
                          >
                            I
                          </span>
                          rrigation{" "}
                          <span
                            className="text-warning"
                            style={{ color: "#e2d023" }}
                          >
                            P
                          </span>
                          roject for{" "}
                          <span
                            className="text-warning"
                            style={{ color: "#e2d023" }}
                          >
                            C
                          </span>
                          limate{" "}
                          <span
                            className="text-warning"
                            style={{ color: "#e2d023" }}
                          >
                            R
                          </span>
                          esilient{" "}
                          <span
                            className="text-warning"
                            style={{ color: "#e2d023" }}
                          >
                            A
                          </span>
                          griculture{" "}
                        </div>
                        {/* <div
                          className='text-center display-2 text-white'
                          style={{ fontWeight: '400' }}
                        >
                          <span className='text-warning'>O</span>disha{' '}
                          <span className='text-warning'>P</span>ublic{' '}
                          <span className='text-warning'>A</span>sset{' '}
                          <span className='text-warning'>M</span>anagement{' '}
                          <span className='text-warning'>S</span>ystem{' '}
                        </div> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
